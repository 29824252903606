import { graphql } from "gatsby"

export const siteTitle = "Impro Impact"

export const halfWidthBreakpoints = [1, 1, 1 / 2, 1 / 2, 1 / 2, 1 / 2]
export const stackedParallaxedYMargin = [5, 5, 2, 2, 2, 2]
export const paddingBreakpoints = [2, 2, 3, 4, 4, 5]
export const iconWidthBreakpoints = [
    "2em",
    "2.1em",
    "2.2em",
    "2.3em",
    "2.4em",
    "2.5em",
]
export const lettersSpacingBreakpoints = [
    "0.18em",
    "0.19em",
    "0.20em",
    "0.21em",
    "0.22em",
    "0.23em",
]

export const contentTitleFontSizeBreakpoints = [10, 14, 14, 16, 18, 20]
export const navbarTitleFontSizeBreakpoints = [15, 22, 30, 37, 44, 50]

export const screenWidthItemsPaddingBreakpoints = [10, 20, 40, 60, 80, 100]

export const defaultImage = graphql`
    fragment defaultImage on File {
        childImageSharp {
            fluid(maxWidth: 4096) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`
